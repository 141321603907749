<template>
  <v-card
    :max-height="slide ? '250' : ''"
    :width="slide ? '150' : ''"
    class="fill-height rounded-lg text-center"
    :to="{ name: 'profile', params: { slug: content.slug }}"
    v-if="content"
    >
    <v-avatar
      size="60"
      class="mt-2"
      color="secondary"
      >
      <v-img
        v-if="content.picture"
        :src="url + content.picture.url"
        ></v-img>
      <v-icon
        v-else
        color="white"
        >
        mdi-storefront
      </v-icon>
    </v-avatar>

    <v-card-text
      class="d-flex flex-column justify-center pt-1 pb-2"
      >
      <div 
        class="text-subtitle-2 font-weight-bold text-center secondary--text"
        >
        {{ content.name }}
      </div>
      <div
        class="caption text-center"
        >
        {{ content.followerIds.length  }} fans
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    slide: {
      required: false,
      type: Boolean,
      default: false
    },
    content: {
      type: Object,
      required: true
    },
  },

  computed: {
    url () {
      return process.env.VUE_APP_RAILS_URL
    }
  },
}
</script>
</script>
