<template>
  <div>
    <v-dialog
      v-model="dialog"
      :fullscreen="$vuetify.breakpoint.mobile"
      width="650"
      >
      <category
        :category="category"
        :store="store"
        @close="dialog = false"
        ></category>
    </v-dialog>
    <div
      class="text-subtitle-1 ml-2 font-weight-medium d-flex justify-space-between py-1"
      >
      <div>
        {{ title }}
      </div>
      <div>
        <v-btn
          v-if="store"
          x-small
          text
          color="primary"
          @click="dialog = true"
          >
          ver todo
        </v-btn>
      </div>
    </div>
    <v-slide-group>
      <v-slide-item
        v-for="card in cards"
        :key="card.id"
        >
        <template
          v-if="typename == 'Product'"
          >
          <thumb 
            :store="store"
            :slide="true"
            :content="card"
            ></thumb>
        </template>
        <template
          v-else
          >
          <store 
            :store="store"
            :slide="true"
            :content="card"
            ></store>
        </template>
      </v-slide-item>
    </v-slide-group>
  </div>
</template>

<script>
import { CategoryProducts } from '@/graphql/queries/categories'

import Thumb from '@/components/Thumb'
import Store from '@/components/profiles/Thumb'

const Category = () => import('@/components/categories/Category')

export default {
  data () {
    return {
      dialog: false,
      products: []
    }
  },

  components: { 
    Thumb, Store, Category
  },

  props: {
    typename: {
      type: String,
      required: false,
      default: 'Product'
    },
    content: {
      type: Array,
      required: false,
    },
    category: {
      type: Object,
      required: false,
    },
    store: {
      type: Object,
      required: false,
    },
    head: {
      type: String,
      required: false
    }
  },

  created () {
    if (this.category) {
      this.fetchProducts ()
    } else {
      if (this.typename == 'Product') {
        this.products = this.content
      } else {
        this.stores = this.content
      }
    }
  },

  methods: {
    fetchProducts () {
      this.$apollo.query({
        query: CategoryProducts,
        variables: {
          storeSlug: this.store.slug,
          slug: this.category.slug
        }
      }).then ( res => {
        this.products = res.data.categoryProducts
      })
    }
  },

  computed: {
    cards () {
      if (this.typename == 'Product') {
        return this.products
      } else {
        return this.stores
      }
    },

    title () {
      if (this.head) {
        return this.head
      } else {
        return 'Productos para tí'
      }
    }
  },
}
</script>

<style scoped>
>>>.v-slide-group__content {
  margin-bottom: 4px;
  gap: 12px
}
</style>
